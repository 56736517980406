.about-us {
  width: 100%;

  }
  
  .text {
    margin-top: -10%;
    margin-bottom: 12%;
    width: 50%;
    padding: 5%;
    z-index: 2;
    padding-top: 5%;
    color: white;
    text-align: left;
  }
  
  h4{
    color:#FFBD59; 
  }


  

/* Styling for the image container */
.sat1-img {
  min-width: 30px; /* Set a smaller minimum width for the sat-img container */
  width: 50%; /* Ensure the image takes up the full width within the container */
  height: 20%; /* Maintain the aspect ratio of the image */
  margin-top: -60%; /* Adjust as needed based on your layout */
  margin-left: 99%; /* Move the image to the right */
  z-index: 1;
  padding: 0;
}