/* styles.css */
body{
  background-color: #000000;
}
/* Styling for the logo image */
.logo-img {
  position: absolute;
  left: -7%;
  width: 50%;
  z-index: 2; /* Set a higher z-index for the logo */
  margin-top: -22%;
  margin-left: 2%;
}

.center-panel {
  position: relative;
  height: 600px;
  width: 100%; /* Set your desired maximum width */
  margin: 0; /* Center the panel horizontally */
  z-index: 1; /* Set a lower z-index for the side image */
  margin-top: 8%;
  margin-bottom: 12%;
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Center items vertically */
}

.headings {
  color: #FFBD59;
  font-size: 2em;
  padding-bottom: 6%;
  margin-top: -12%;
}

/* Styling for the image */
.side-img {
  height: 600px;
  filter: brightness(50%);
  opacity: 90%;
  width: 100%;
}

.main-info {
  position: absolute;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 4em;
  z-index: 3;
  opacity: 90%;
  text-align: center;
  opacity: 0; /* Set initial opacity to 0 */
  animation: fadeIn 1.5s ease-in-out forwards;
  width: 100%; /* Ensure the width is 100% for responsiveness */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
