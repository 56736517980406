.card {
    position: relative;
    width: 300px;
    height: 480px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    padding: 10pt;
    background-color: whitesmoke; 
  }
  
  .card img {
    width: 100%;
    height: 60%;
    object-fit: cover;
  }
  
  .card-content {
    padding: 20px;
    text-align: center;
  }
  
  .card-content h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .card-content p {
    color: #666;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  

  .card-container {
    display: flex;
    justify-content: space-around;
    padding-bottom: 5%;
    margin-bottom: 10%;

  }
  
  /* Optional: Add some margin to the cards for spacing */
  .card {
    margin: 10px;
  }
  