/* Resetting default margin and padding for the page */
body, h1, h2, h3, p, ul {
  margin: 0;
  padding: 0;
  font-size: large;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* Basic styling for the header */
.header {
  background-color:black; /* Choose your desired background color */
  color: #FFBD59; /* Choose your desired text color */
  padding: 10px;
}

/* Styling for the navigation menu */
.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-left: 70%;
}

.nav li {
  display: inline-block; /* Change from float: left to display: inline-block */
}

.nav a {
  display: block;
  color:white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change color on hover */
.nav a:hover {
  background-color: #FFBD59; /* Choose your desired hover color */
  color: #333;
}

/* Styling for the navigation bar */
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: #333;
}

.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 1.2em;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .nav-links {
      flex-direction: row; /* Set back to row for smaller screens */
      justify-content: center;
  }

  .nav-links li {
      margin: 0 10px; /* Adjust spacing for smaller screens */
  }
}
